<!-- eslint-disable unused-imports/no-unused-imports -->

<template>
  <b-modal
    id="animal-milkings-chart"
    :title="$t('animalMilkingChart')"
    centered
    size="lg"
    :ok-title="$t('close')"
    ok-only
  >
    <b-row class="mb-2">
      <b-col cols="12" md="6">
        <h2 id="animal-milk-chart-animal-ear">{{ animal.earingno }}</h2>
      </b-col>
      <b-col class="d-flex flex-row-reverse" cols="12" md="6">
        <b-button
          variant="success"
          class="d-flex align-items-center"
          @click="printChart"
        >
          <feather-icon icon="PrinterIcon" size="22" />
          <span class="align-middle ml-2">{{ $t("print") }}</span>
        </b-button>
      </b-col>
    </b-row>
    <div style="position: relative">
      <line-chart
        :key="height"
        class="print"
        :height="height"
        :data="data"
        :options="options"
      />
    </div>
  </b-modal>
</template>

<script>
import { BModal, BCol, BRow, BButton } from "bootstrap-vue";
import LineChart from "@/views/components/LineChart.vue";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
export default {
  components: {
    BModal,
    LineChart,
    BCol,
    BRow,
    BButton,
  },
  props: {
    animal: {
      type: Object,
      required: true,
    },
    // { "milk": 33, "milk": 2, "recordeddate": "2023-06-06T16:20:19" }
    milkings: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dayjs,
      data: {
        labels: [],
        datasets: [
          {
            label: this.$t("milk"),
            yAxisID: "y",
            backgroundColor: this.transparentize("#4c51bf", 0.92),
            borderColor: "#4c51bf",
          },

          {
            label: this.$t("averageMilk"),
            yAxisID: "y",
            backgroundColor: this.transparentize("#38a169", 1),
            borderColor: "#38a169",
            borderDash: [5, 5],
            borderDashOffset: 10,
            data: [32],
          },
        ],
      },

      options: {
        responsive: true,
        aspectRatio: 1,

        interaction: {
          mode: "index",
          intersect: true,
        },
        stacked: true,
        plugins: {
          title: {
            display: true,
            text: this.$t("animalMilkChart"),
          },
        },
        scales: {
          yAxes: [
            {
              type: "linear",
              display: true,
              position: "left",
              id: "y",
              text: this.$t("milk"),
              scaleLabel: {
                display: true,
                labelString: this.$t("milk"),
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    height() {
      return this.$store.state.app.windowWidth >= 1480
        ? 100
        : this.$store.state.app.windowWidth >= 1200
        ? 150
        : this.$store.state.app.windowWidth >= 768
        ? 250
        : this.$store.state.app.windowWidth >= 375
        ? 350
        : 450;
    },
  },
  watch: {
    milkings: {
      handler() {
        this.setData();
      },
      deep: true,
    },
  },
  mounted() {
    this.setData();
  },
  methods: {
    printChart() {
      var headerDiv = document.querySelector("#animal-milk-chart-animal-ear");

      var canvas = document.querySelector(".print canvas");
      var img = canvas.toDataURL("image/png");
      var win = window.open();
      win.document.write(headerDiv.outerHTML);
      win.document.write(
        '<img src="' + img + '" onload="window.print();window.close()" />'
      );
      window.document.close();
      win.print();
      win.location.reload();
    },
    transparentize(value, opacity) {
      var alpha = opacity === undefined ? 0.5 : 1 - opacity;
      return (
        "rgba(" +
        parseInt(value.slice(-6, -4), 16) +
        "," +
        parseInt(value.slice(-4, -2), 16) +
        "," +
        parseInt(value.slice(-2), 16) +
        "," +
        alpha +
        ")"
      );
    },
    setData() {
      if (this.milkings.length === 0) {
        this.data.datasets[0].data = [];
        this.data.datasets[1].data = [];
        this.data.labels = [];
        return;
      }
      this.data.datasets[0].data = this.milkings
        .map((c) => c.totalmilking)
        .reverse();
      var average =
        this.milkings.map((c) => c.totalmilking).reduce((a, b) => a + b, 0) /
        this.milkings.length;
      this.data.datasets[1].data = this.milkings.map((c) => average);
      this.data.labels = this.milkings
        .map((c) => dayjs(c.milkingdate).format("DD.MM.YYYY"))
        .reverse();
    },
  },
};
</script>
