import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useMilkingList() {
  // Use toast
  const toast = useToast();

  const refMilkingListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "animalid",
      label: VueI18n.t("animal"),
    },
    {
      key: "chart",
      label: VueI18n.t("chart"),
    },
    {
      key: "lactation",
      label: VueI18n.t("lactation"),
      sortable: false,
    },
    {
      key: "padokname",
      label: VueI18n.t("padokName"),
      sortable: false,
    },
    {
      key: "aveOnGunSut",
      label: VueI18n.t("aveOnGunSut"),
      formatter: (val) => `${val} KG`,
    },
    {
      key: "aveYuzGunSut",
      label: VueI18n.t("aveYuzGunSut"),
      formatter: (val) => `${val} KG`,
    },

    {
      key: "sagimtoplam",
      label: VueI18n.t("totalMilking"),
      formatter: (val) => `${val} KG`,
    },
    {
      key: "milking1time",
      label: VueI18n.t("milking1Time"),
      sortable: false,
    },
    {
      key: "milking1duration",
      label: VueI18n.t("milking1Duration"),
      sortable: false,
      formatter: (val) => `${val} ${VueI18n.t("second")}`,
    },
    {
      key: "milking1amount",
      label: VueI18n.t("milking1Amount"),
      sortable: true,
      formatter: (val) => `${val} KG`,
    },
    {
      key: "milking2time",
      label: VueI18n.t("milking2Time"),
      sortable: false,
    },
    {
      key: "milking2duration",
      label: VueI18n.t("milking2Duration"),
      sortable: false,
      formatter: (val) => `${val} ${VueI18n.t("second")}`,
    },
    {
      key: "milking2amount",
      label: VueI18n.t("milking2Amount"),
      sortable: true,
      formatter: (val) => `${val} KG`,
    },
    {
      key: "milking3time",
      label: VueI18n.t("milking3Time"),
      sortable: false,
    },
    {
      key: "milking3duration",
      label: VueI18n.t("milking3Duration"),
      sortable: false,
      formatter: (val) => `${val} ${VueI18n.t("second")}`,
    },
    {
      key: "milking3amount",
      label: VueI18n.t("milking3Amount"),
      sortable: true,
      formatter: (val) => `${val} KG`,
    },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const date = ref(dayjs().format("YYYY-MM-DD"));
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const allMilking = ref([]);
  const filteredMilkings = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredMilkings.value.length;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to:
        perPage.value * (currentPage.value - 1) +
          perPage.value * currentPage.value <
        localItemsCount
          ? perPage.value
          : localItemsCount,
      of: allMilking.value.length,
    };
  });

  const refetchData = () => {
    if (date.value != dayjs().format("YYYY-MM-DD")) {
      fetchMilkings();
    }
    if (searchQuery.value)
      filteredMilkings.value = allMilking.value.filter((val) =>
        val.milkingName.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    else filteredMilkings.value = allMilking.value;
  };
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      date,
    ],
    () => {
      refetchData();
    }
  );
  const fetchMilkings = async (ctx, callback) => {
    var milking = await store
      .dispatch("animalsModule/fetchAnimalMilkings", {
        reportdate: date.value,
      })

      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("milkings") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allMilking.value = milking;
    filteredMilkings.value = [...milking];
    return milking;
  };

  const deleteMilking = (ctx) => {
    store
      .dispatch("animalsModule/deleteMilking", ctx)
      .then((response) => {
        fetchMilkings();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("milking") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("milking") }),
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchMilkings,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMilkingListTable,
    deleteMilking,
    allMilking,
    filteredMilkings,
    date,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
