<template>
  <div>
    <animal-milk-chart :milkings="allMilking" :animal="selectedAnimal" />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-datepicker v-model="date" :locale="$i18n.locale" />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mx-2"
                :placeholder="$t('search')"
              />
            </div>
          </b-col>
          <!-- Per Page -->

          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
          >
            <label>{{ $t("eachPage") }}</label>
            <v-select
              v-model="perPage"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> {{ $t("piece") }} </label>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refMilkingListTable"
        class="position-relative"
        :items="filteredMilkings"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('noRecords')"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #cell(animalid)="data">
          <b-link
            :to="{ name: 'animal-milk', query: { id: data.item.animalid } }"
          >
            {{ $t("animalMilking") }}
          </b-link>
        </template>
        <template #cell(chart)="data">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="openChart(data.item)"
          >
            <feather-icon icon="BarChart2Icon" />
          </b-button>
        </template>
        <template #cell(delete)="data">
          <b-link
            v-if="data.item.allowDelete == 1"
            @click="deleteMilking(data.item.id)"
          >
            <div class="d-flex">
              <feather-icon icon="TrashIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("delete")
              }}</small>
            </div>
          </b-link>
        </template>

        <template #cell(edit)="data">
          <b-link
            v-if="data.item.allowEdit == 1"
            @click="openEditSideBar(data)"
          >
            <div class="d-flex">
              <feather-icon icon="EditIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("edit")
              }}</small>
            </div>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }} </span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filteredMilkings.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BLink,
  BPagination,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import { ref, getCurrentInstance } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useMilkingList from "./useMilkingList";
import AnimalMilkChart from "@/views/components/AnimalMilkChart.vue";

import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    BFormDatepicker,
    vSelect,
    BButton,
    AnimalMilkChart,
  },
  data() {
    return {};
  },

  setup({ emit }) {
    const isAddNewMilkingSidebarActive = ref(false);
    const isEditMilkingSidebarActive = ref(false);
    const selectedMilking = ref({});
    const userData = getUserData();
    const openEditSideBar = ({ item }) => {
      selectedMilking.value = item;
      isEditMilkingSidebarActive.value = !isEditMilkingSidebarActive.value;
    };
    var instance = getCurrentInstance();

    const allMilkings = ref([]);
    const selectedAnimal = ref({ earingno: "" });
    const openChart = async (item) => {
      var milkings = await fetchMilkings(item.animalid);
      selectedAnimal.value.earingno = item.earingno;
      allMilkings.value = milkings;
      // animal-condition-chart modal open
      instance.proxy.$bvModal.show("animal-milkings-chart");
    };

    const {
      fetchMilkings,
      tableColumns,
      perPage,
      currentPage,
      totalMilkings,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMilkingListTable,
      refetchData,
      filteredMilkings,
      allMilking,
      date,
      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteMilking,
    } = useMilkingList();
    fetchMilkings();
    return {
      // Sidebar
      isAddNewMilkingSidebarActive,
      isEditMilkingSidebarActive,
      openEditSideBar,
      selectedAnimal,
      selectedMilking,
      filteredMilkings,
      allMilking,
      openChart,
      fetchMilkings,
      tableColumns,
      perPage,
      currentPage,
      totalMilkings,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMilkingListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      date,
      // UI

      // Extra Filters
      typeFilter,
      genderFilter,
      deleteMilking,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
